import { TREnv } from "@tractableai/auth-management-api";

export type Product = {
  /** Name to show in the TRAM UI. Customers don't see this. */
  uiName: string;

  /** Canonical product ID, as defined in the user pool repo. */
  productId: string;

  /** URL to redirect the user to after they confirm their account. */
  homeUrl: string;
};

const propertyDomains = {
  dev: [
    "afics",
    "ad",
    "axa-be",
    "bd",
    "demo",
    "ja",
    "ms",
    "pzu",
    "sompo",
    "sun",
    "tmnf",
    "aig",
    "kohken",
  ],
  eu: ["axa-be", "demo", "pzu", "sun"],
  jp: ["aioi", "bd", "demo", "ja", "ms", "sompo", "tmnf", "aig", "kohken"],
  us: ["afics", "demo"],
};

const propertyProductId = "property-claim-handler";

const underwritingDomains = {
  dev: ["ad", "bd", "demo", "ms"],
  jp: ["ad", "bd", "demo", "ms"],
};

const underwritingProductId = "underwriting-management-portal";

// APP
const appDomains = {
  dev: ["demo1", "kirmac", "selectone"],
  us: ["demo1", "kirmac", "selectone"],
};

const appProductId = "auto-platform-product-portal";

export const AllProducts: Record<TREnv, Array<Product>> = {
  dev: [
    {
      uiName: "Test product",
      homeUrl: "https://tractable.ai",
      productId: "test",
    },
    ...underwritingDomains.dev.map((domain) => {
      return {
        uiName: `Underwriting Management Portal (${domain})`,
        homeUrl: `https://${domain}.portal.uw.dev.tractable.io`,
        productId: underwritingProductId,
      };
    }),
    ...propertyDomains.dev.map((domain) => {
      return {
        uiName: `Property Claim Handler (${domain})`,
        homeUrl: `https://${domain}.portal.property.dev.tractable.io`,
        productId: propertyProductId,
      };
    }),
    ...appDomains.dev.map((domain) => {
      return {
        uiName: `Auto Platform Product (${domain})`,
        homeUrl: `https://${domain}.auto.dev.tractable.io/auto-portal`,
        productId: appProductId,
      };
    }),
  ],
  integ: [],
  "prod-jp": [
    ...underwritingDomains.jp.map((domain) => {
      return {
        uiName: `Underwriting Management Portal (${domain}, Prod)`,
        homeUrl: `https://${domain}.portal.uw.jp.tractable.io`,
        productId: underwritingProductId,
      };
    }),
    ...underwritingDomains.jp.map((domain) => {
      return {
        uiName: `Underwriting Management Portal (${domain}, Preprod)`,
        homeUrl: `https://${domain}.portal.uw.preprod-jp.tractable.io`,
        productId: underwritingProductId,
      };
    }),
    ...propertyDomains.jp.map((domain) => {
      return {
        uiName: `Property Claim Handler (${domain}, Prod)`,
        homeUrl: `https://${domain}.portal.property.jp.tractable.io`,
        productId: propertyProductId,
      };
    }),
    ...propertyDomains.jp.map((domain) => {
      return {
        uiName: `Property Claim Handler (${domain}, Preprod)`,
        homeUrl: `https://${domain}.portal.property.preprod-jp.tractable.io`,
        productId: propertyProductId,
      };
    }),
  ],
  "prod-eu": [
    ...propertyDomains.eu.map((domain) => {
      return {
        uiName: `Property Claim Handler (${domain}, Prod)`,
        homeUrl: `https://${domain}.portal.property.eu.tractable.io`,
        productId: propertyProductId,
      };
    }),
    ...propertyDomains.eu.map((domain) => {
      return {
        uiName: `Property Claim Handler (${domain}, Preprod)`,
        homeUrl: `https://${domain}.portal.property.preprod-eu.tractable.io`,
        productId: propertyProductId,
      };
    }),
  ],
  "prod-us": [
    ...propertyDomains.us.map((domain) => {
      return {
        uiName: `Property Claim Handler (${domain}, Prod)`,
        homeUrl: `https://${domain}.portal.property.us.tractable.io`,
        productId: propertyProductId,
      };
    }),
    ...propertyDomains.us.map((domain) => {
      return {
        uiName: `Property Claim Handler (${domain}, Preprod)`,
        homeUrl: `https://${domain}.portal.property.preprod-us.tractable.io`,
        productId: propertyProductId,
      };
    }),
    ...appDomains.us.map((domain) => {
      return {
        uiName: `Auto Platform Product (${domain})`,
        homeUrl: `https://${domain}.auto.us.tractable.io/auto-portal`,
        productId: appProductId,
      };
    }),
  ],
};

/** A way to differentiate products with the same product ID. Necessary to
    generate unique values for the Select options when adding a user. */
export function getUniqueProductId(product: Product): string {
  return `PRODUCT_ID#${product.productId}#HOME_URL#${product.homeUrl}`;
}
